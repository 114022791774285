<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>銷售應用中心</b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'PreorderList' }" active>
              預購單列表
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-4 mb-2 font-weight-bold">
          預購單列表
          <b-button
            class="mr-1"
            variant="inverse-dark"
            @click="showFormUrl"
          >
            <i class="fa fa-link"></i>
          </b-button>
        </h4>
        <b-input-group class="col-12 col-xl-8 mb-4">
          <b-form-select
            id="branch-select"
            v-model="selectedBranch"
            :options="formattedBranches"
            @change="handleBranchChange"
            class="mr-2 mb-2 mb-xl-0"
          >
            <template #first>
              <b-form-select-option :value="null">請選擇訂單櫃點</b-form-select-option>
            </template>
          </b-form-select>
          <b-dropdown
            text="匯出"
            variant="primary"
            class="mr-2"
          >
            <b-dropdown-item @click="listExport">清單匯出</b-dropdown-item>
            <b-dropdown-item @click="detailExport">明細匯出</b-dropdown-item>
          </b-dropdown>
          <h6 class="mr-2 my-auto">
            搜尋建立日期：
          </h6>
          <DateSearchPicker
            class="mr-md-2 mr-0"
            v-model="query"
            :disabledEndAtDates.sync="disabledEndAtDates"
            startedPlaceholder="開始時間"
            endedPlaceholder="結束時間"
            @input="handleDateChange"
          />
          <b-form-input class="col-xl-3" v-model="query.keyword" placeholder="搜尋"></b-form-input>
          <b-input-group-append>
            <b-button @click="handlePageReset">
              <i class="fa fa-search"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <!-- <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.PREORDER_SETTING_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'PreorderSettingCreate' }"
          >
            <i class="fa fa-plus"></i>新增預購單設定檔
          </b-button>
        </div>
      </div> -->
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="preorders"
                :fields="fields"
              >
                <!-- <template #cell(is_enabled)="data">
                  <b-badge
                    v-if="data.item.is_enabled == true"
                    variant="success"
                  >
                    啟用
                  </b-badge>
                  <b-badge
                    v-if="data.item.is_enabled == false"
                    variant="secondary"
                  >
                    停用
                  </b-badge>
                </template> -->
                <template #cell(customer_name)="data">
                  {{ data.item.customer ? data.item.customer.name : '無' }}
                </template>
                <template #cell(preorder_setting_title)="data">
                  {{ data.item.preorderSetting ? data.item.preorderSetting.title : '無' }}
                </template>
                <template #cell(branch_name)="data">
                  {{ data.item.branch ? data.item.branch.name : '無' }}
                </template>
                <template #cell(status)="data">
                  {{ displayStatusText(data.item.status) }}
                </template>
                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    variant="inverse-warning"
                    :to="{
                      name: 'PreorderDetail',
                      params: { id: preorderSettingId , preorder_id: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-lead-pencil"></span> 查看訂單
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.PREORDER_SETTING_DELETE])"
                    variant="inverse-danger"
                    @click="deletePreorder(data.item)"
                  >
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-card-text>
    </b-card>
    <b-modal
      id="form-url-modal"
      footer-class="d-flex justify-content-center"
      hide-header
    >
      <h5 class="mb-2">預購網址</h5>
      <b-textarea
        id="form-url"
        v-b-tooltip.click="'已複製'"
        v-model="formUrl"
        @click="copyFormUrl('#form-url')"
      ></b-textarea>
      <template #modal-footer>
        <b-button
          size="lg"
          variant="danger"
          @click="$bvModal.hide('form-url-modal')"
          >關閉</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import {format, set} from "date-fns";
import preorderApi from "@/apis/preorder";
import { mapState, mapGetters } from "vuex";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import generateLiffUrl from "@/mixins/Dashboard/liffUrlHelper";
import { paginationMixin } from "@/mixins/pagination";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import DateSearchPicker from "@/components/Page/Dashboard/DateSearchPicker";

export default {
  components: {DateSearchPicker, CustomPagination },
  mixins: [generateLiffUrl, paginationMixin],
  data() {
    const baseFields = [
      { key: "preorder_number", label: "預購單編號", sortable: false },
      { key: "customer_name", label: "會員名稱", sortable: false },
      { key: "preorder_setting_title", label: "活動名稱", sortable: false },
      { key: "total_amount", label: "訂單金額", sortable: false },
      { key: "total_points", label: "訂單點數總計", sortable: false },
      { key: "branch_name", label: "訂單櫃點", sortable: false },
      {
        key: "created_at",
        label: "建立時間",
        sortable: true,
        formatter: (value) => {
          return format(new Date(value), "yyyy-MM-dd HH:mm");
        },
      },
      { key: "status", label: "訂單狀態", sortable: false },
    ];

    return {
      consts: consts,
      preorderSettingId: null,
      preorderSetting: {},
      preorders: [],
      branches: [],
      showLoading: false,
      baseFields: baseFields,
      fields: baseFields,
      totalRows: 0,
      initialized: false,
      query : {
        keyword: "",
        per_page: 20,
        start_at: "",
        end_at: "",
        page: 1,
        branch_id: null,
      },
      formUrl: null,
      exportDetail: false,
      selectedBranch: null,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
    disabledEndAtDates() {
      return { to: new Date(this.query.start_at) };
    },
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig"
    }),
    formattedBranches() {
      return this.branches.map(branch => ({
        value: branch.id, // 確保有正確的 key，例如 id 或其他唯一值
        text: branch.name // 顯示的名稱
      }));
    }
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.fetchPreorders();
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
    this.fetchPreorders();
    this.initialized = true;
    this.preorderSettingId = this.$route.params.id;
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async fetchPreorders() {
      try {
        this.showLoading = true;
        let preorderSettingId = this.$route.params.id;
        let params = {
          branch_id: this.query.branch_id ? this.query.branch_id : "",
          keyword: this.query.keyword ? this.query.keyword : "",
          page: this.query.currentPage,
          per_page: this.query.perPage,
          ...(this.query.start_at
            ? {
              start_at: format(
                set(new Date(this.query.start_at), {
                  hours: "00",
                  minutes: "00",
                  seconds: "00",
                }),
                "yyyy-MM-dd HH:mm:ss"
              ),
            }
            : {}),
          ...(this.query.end_at
            ? {
              end_at: format(
                set(new Date(this.query.end_at), {
                  hours: "23",
                  minutes: "59",
                  seconds: "59",
                }),
                "yyyy-MM-dd HH:mm:ss"
              ),
            }
            : {}),
        };

        let response = await preorderApi.getPreorderList(preorderSettingId, params);
        let settingResponse = await preorderApi.getPreorderSetting(preorderSettingId);
        this.preorderSetting = settingResponse.data.data;

        this.preorders = response.data.data;
        this.totalRows = response.data.meta.total;
        this.branches = response.data.branches;

        if (this.query.branch_id != null) {
          this.selectedBranch = this.query.branch_id;
        }

        let normalizeProfileFields = await this.normalizeProfileFields();
        // 找到插入的位置
        const insertIndex = this.baseFields.findIndex(
          (field) => field.key === "preorder_setting_title"
        );

        // 創建新的欄位陣列，插入 normalizeProfileFields
         this.fields = [
          ...this.baseFields.slice(0, insertIndex), // 從開頭到插入點之前的欄位
          ...normalizeProfileFields,          // 插入 normalizeProfileFields
          ...this.baseFields.slice(insertIndex),   // 插入點之後的欄位
        ];

        // 加上 actions 欄位
        this.fields.push({ key: "actions", label: "管理" });
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取列表失敗", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async deletePreorder(preorder) {
      try {
        this.showLoading = true;
        const result = await this.$swal({
          type: "warning",
          title: "您確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>刪除訂單：${preorder.preorder_number}</div>
              </div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        });

        if (!result.value) {
          return;
        }

        await preorderApi.deletePreorder(preorder.id);
        this.$swal("刪除成功", "", "success");
        await this.fetchPreorders();
      } catch (error) {
        console.error(error);
        this.$swal("刪除失敗", "", "error");
      } finally {
        this.showLoading = false;
      }
    },
    detailExport() {
      this.exportDetail = true;
      this.handleExport();
    },
    listExport() {
      this.exportDetail = false;
      this.handleExport();
    },
    async handleExport() {
      try {
        this.showLoading = true;
        let preorderSettingId = this.$route.params.id;

        let params = {
          branch_id: this.query.branch_id,
          export_detail: this.exportDetail,
          keyword: this.query.keyword ? this.query.keyword : "",
          page: this.query.currentPage,
          per_page: this.query.perPage,
          ...(this.query.start_at
            ? {
              start_at: format(
                set(new Date(this.query.start_at), {
                  hours: "00",
                  minutes: "00",
                  seconds: "00",
                }),
                "yyyy-MM-dd HH:mm:ss"
              ),
            }
            : {}),
          ...(this.query.end_at
            ? {
              end_at: format(
                set(new Date(this.query.end_at), {
                  hours: "23",
                  minutes: "59",
                  seconds: "59",
                }),
                "yyyy-MM-dd HH:mm:ss"
              ),
            }
            : {}),
        };

        await preorderApi.exportPreorderList(preorderSettingId, params);

        this.$swal({
          title: "成功",
          text: "可至“我的主控台”查看紀錄及下載檔案",
          showCancelButton: true,
          cancelButtonText: '確認',
          type: "success",
          confirmButtonText: "前往查看",
        }).then((result) => {
          if (result.value) {
            this.$router.push({
              name: "MyConsoleDownloadFileList",
              params: { org_code: this.$route.params.org_code },
            });
          }
        });
      } finally {
        this.showLoading = false;
      }
    },
    async normalizeProfileFields() {
      const customerList = this.getModuleConfig("customer", "preorder.customer_list");

      const profileMappings = customerList?.[0]?.member_fields ?? [];
      if (profileMappings) {
        return profileMappings.map((mapping) => {
          if (mapping.matched_key.startsWith("extra.")) {
            const key = mapping.matched_key.split("extra.")[1];
            return {
              key: `profiles.${key}`,
              label: mapping.title,
            };
          } else {
            return {
              key: mapping.matched_key,
              label: mapping.title,
            };
          }
        });
      } else {
        return [];
      }
    },
    dateFormatter: (value) => {
      if (!value) return "無";
      return format(new Date(value), "yyyy-MM-dd");
    },
    displayStatusText(statusValue) {
      // console.log('status text', this.preorderSetting);

      if (! this.preorderSetting?.config?.status) {
        return "無";
      }

      const status = this.preorderSetting.config.status.find((s) => s.value === statusValue);

      return status.title;
    },
    showFormUrl() {
      this.formUrl = this.generateLiffUrl(`preorder/${this.$route.params.id}`);
      this.$bvModal.show("form-url-modal");
    },
    copyFormUrl(id) {
      const inputText = document.querySelector(id);
      inputText.select();
      document.execCommand("copy");
    },
    handleDateChange(date) {
      const formatDate = (date) => {
        if (!date) return null;
        if (typeof date === "string") return date;
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };
      this.query.start_at = formatDate(date.start_at);
      this.query.end_at = formatDate(date.end_at);
    },
    handleBranchChange() {
      this.query.branch_id = this.selectedBranch;
    },
  },
};
</script>
